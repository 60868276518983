import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import React from "react";
import { checkThemeIdFormat, isEmpty, isUrl } from "../../../utils/Util";

import {
  GetPaymentMethods,
  GetDealerPaymentMethods,
  JWTDecode,
  TargetPayApi,
} from "../../../utils/UtilTarget";
import { StyleCreator } from "../../../helpers/stylecreator";
import { FormStyleCollection as formStyles } from "../../../styles/form";
import { Link } from "react-router-dom";
import PricingModel from "./PricingModel";

export default function PreOnBoarding() {
  const classes = StyleCreator.makeStyles(formStyles.all);

  const baseUrl = window.location.origin + "/login"; // Origin URL;

  const [PricingModels, SetPricingModels] = React.useState<any>({
    SplitConfigurationId: "",
    Description: "Choose One",
    MarkupRate: null,
    MarkupFee: null,
    MonthlyFee: null,
  });

  const [DealerStatus, SetDealerStatus] = React.useState<any>("");
  const [IsDealerOnboarded, SetIsDealerOnboarded] = React.useState<any>(false);
  const [DealerData, SetDealerData] = React.useState<any>([]);
  const [DealersList, SetDealersList] = React.useState<any>([]);
  const [IndustryCode, setIndustryCode] = React.useState<any>({
    Code: "",
    Description: "Choose One",
  });
  const [PreOnBoardingToken, setPreOnBoardingToken] = React.useState<any>("");
  const [isProcessing, setIsProcessing] = React.useState<any>(false);
  const [IndustryCodeData, SetIndustryCodeData] = React.useState<any>([]);
  const [PricingModelList, SetPricingModelList] = React.useState<any>([
    {
      SplitConfigurationId: "",
      Description: "Choose One",
      MarkupRate: null,
      MarkupFee: null,
      MonthlyFee: null,
    },
  ]);

  const [IsPlatformFeeEnabled, SetIsPlatformFeeEnabled] =
    React.useState<any>(false);
  const [PlatformFeePercentage, SetPlatformFeePercentage] =
    React.useState<any>("");
  const [PlatformFeeMsg, SetPlatformFeeMsg] = React.useState<any>("");

  const [isDrawerOpen, setIsDrawerOpen] = React.useState<any>(false);

  const [PaymentMethods, SetPaymentMethods] = React.useState<any>([]);
  const [DealerPaymentMethods, SetDealerPaymentMethods] = React.useState<any>(
    []
  );
  const [paymentLinkThemeId, SetPaymentLinkThemeId] = React.useState<any>("");

  const [isHostUrlValid, setIsHostUrlValid] = React.useState<boolean>(true);
  const [hostUrl, setHostUrl] = React.useState<string>("");

  const [isThemeValid, SetIsThemeValid] = React.useState<boolean>(true);
  const toggleDrawer = (params?: any) => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    document.title = "Setup a Customer";
    const getPreOnboardingContent = async () => {
      await GetIndustryData();
      await GetDealerList();
      await GetPaymentMethod();
    };
    getPreOnboardingContent();
  }, []);

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetIsPlatformFeeEnabled(event.target.checked);
    if (!event.target.checked) SetPlatformFeePercentage("");
  };

  const handleKeyDownEvent = (e: any) => {
    if (e.keyCode === 8) {
      // Disable backspace key interaction
      e.preventDefault();
      e.stopPropagation();
    }
  };
  async function OnTextChange(value: any, key: any) {
    // Reset all variables to default value
    ResetDefaultValues();
    if (value == null) {
      const res: any = new Object();
      res.Name = "";

      SetDealerData(res);
      setIndustryCode(IndustryCodeData[0]);
      SetPricingModelList([
        {
          SplitConfigurationId: "",
          Description: "Choose One",
          MarkupRate: null,
          MarkupFee: null,
          MonthlyFee: null,
        },
      ]);
      SetPricingModels(PricingModelList[0]);
      return;
    }

    setIsProcessing(true);

    const res: any = new Object();
    res.Name = value.Name;
    res.DmsDealerId = value.DmsDealerId;
    res.DealerId = value.DealerId;

    await GetDealer("", res.DealerId);

    setIsProcessing(false);
  }

  const onThemeChanged = (event: any) => {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    if (isEmpty(trimmedValue)) SetIsThemeValid(true);
    else SetIsThemeValid(checkThemeIdFormat(trimmedValue));
    SetPaymentLinkThemeId(trimmedValue);
  };

  const onHostUrlChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const url = value.trim();
    if (isEmpty(url)) setIsHostUrlValid(true);
    else setIsHostUrlValid(isUrl(url));
    setHostUrl(url);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Regular expression to allow only numbers and a decimal point
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(value) || value === "") {
      SetPlatformFeeMsg("");
      SetPlatformFeePercentage(value);
    }
  };

  async function GetIndustryData() {
    let obj: any = new Object();

    obj.query = "PerseusPay.IndustryCode";
    obj.queryBody = "[]";

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    let data = res;
    data.unshift({
      Code: "",
      Description: "Choose One",
    });
    SetIndustryCodeData(data);
  }

  async function GetDealerList() {
    let obj: any = new Object();

    obj.query = "PerseusPay.SearchDealer";
    obj.queryBody = "[]";

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );
    SetDealersList(res);
  }

  // Get Payment Method
  async function GetPaymentMethod() {
    const response = await GetPaymentMethods();
    if (!isEmpty(response)) SetPaymentMethods(response);
  }

  function ResetDefaultValues() {
    SetIsDealerOnboarded(false);
    SetIsPlatformFeeEnabled(false);
    setPreOnBoardingToken("");
    SetPlatformFeePercentage("");
    SetPlatformFeeMsg("");
    SetDealerStatus("");
    SetDealerPaymentMethods([]);
    SetPaymentLinkThemeId("");
    setHostUrl("");
    setIsHostUrlValid(true);
    SetIsThemeValid(true);
  }

  function GetDealerContactInfo(row: any) {
    let phone = row.Phone;
    let email = row.Email;

    if (isEmpty(phone) && isEmpty(email)) return "";
    if (isEmpty(phone) && !isEmpty(email)) return email;
    if (!isEmpty(phone) && isEmpty(email)) return phone;

    return email + ", " + formatPhoneNumber(phone, row);
  }

  function GetDealerAddressInfo(row: any) {
    let city = row.City;
    let stateOrProvince = row.StateOrProvince;

    if (isEmpty(city) && isEmpty(stateOrProvince)) return "";
    if (isEmpty(city) && !isEmpty(stateOrProvince)) return stateOrProvince;
    if (!isEmpty(city) && isEmpty(stateOrProvince)) return city;

    return city + ", " + stateOrProvince;
  }

  function formatPhoneNumber(inputNumber: any, row: any) {
    let formattedNumber = "";
    if (
      row.CountryCode == "US" ||
      row.CountryCode == "USA" ||
      row.CountryCode == "U.S" ||
      !isNaN(row.ZipPostal)
    ) {
      const cleaned = ("" + inputNumber).replace(/\D/g, "");
      if (cleaned.length === 10) {
        formattedNumber = cleaned.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2-$3"
        );
      } else {
        formattedNumber = cleaned;
      }
    } else {
      const cleanedPhoneNumber = inputNumber.replace(/\D/g, "");

      if (cleanedPhoneNumber.length === 10) {
        formattedNumber = cleanedPhoneNumber.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2-$3"
        );
      } else {
        formattedNumber = cleanedPhoneNumber;
      }
    }

    return formattedNumber;
  }

  async function GetDealer(dealerGuid: any, dealerId: any) {
    if (isEmpty(dealerId)) return;

    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.Tag = "Select";
    objBody.DealerGuid = dealerGuid;
    objBody.DealerId = dealerId;

    obj.query = "PerseusPay.DealerSetup";
    obj.queryBody = objBody;

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );
    if (!isEmpty(res)) {
      if (!isEmpty(res.MerchantId)) {
        await GetPricingModels(
          res.MerchantId,
          dealerId,
          res.SplitConfigurationId
        );
      } else {
        DealerData.MerchantId = res.DefaultMerchantId;
        res.MerchantId = res.DefaultMerchantId;
        await GetPricingModels(res.MerchantId, res, "");
      }

      if (!isEmpty(res.ThemeId)) {
        SetPaymentLinkThemeId(res.ThemeId);
      }

      if (!isEmpty(res.HostUrl)) {
        setHostUrl(res.HostUrl);
      }

      if (!isEmpty(res) && isEmpty(res.SplitConfigurationId))
        res.SplitConfigurationId = "ChooseOne";

      if (isEmpty(res.MerchantId)) res.MerchantId = DealerData.MerchantId;
      const foundItem = IndustryCodeData.find(
        (item) => item.Code === res.IndustryCode
      );

      if (foundItem == null) setIndustryCode(IndustryCodeData[0]);
      else setIndustryCode(foundItem);
      SetDealerData(res);

      SetIsPlatformFeeEnabled(res.IsPlatformFeeEnabled);

      if (res.PlatformFeePercentage != null && res.PlatformFeePercentage != 0) {
        SetPlatformFeePercentage(res.PlatformFeePercentage);
      }

      // Get Dealer Payment Methods
      const response = await GetDealerPaymentMethods(dealerId, res.StoreId);

      if (!isEmpty(response)) SetDealerPaymentMethods(response);
      else SetDealerPaymentMethods(PaymentMethods);

      if (!isEmpty(res.LegalEntityId)) SetIsDealerOnboarded(true);
      if (isEmpty(res.VerificationStatus) || res.VerificationStatus != "valid")
        await GetPreOnBoardingLink(res.DealerId);

      SetDealerStatus(res.VerificationStatus);
    }
    return res;
  }

  async function GetPricingModels(
    merchantId: any,
    dealerData: any,
    splitConfigurationId: any
  ) {
    if (isEmpty(merchantId) == null && isEmpty(dealerData)) return;

    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.MerchantId = merchantId;

    obj.query = "PerseusPay.PricingModels";
    obj.queryBody = objBody;

    const resPricingModels = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    if (resPricingModels == "") {
      SetPricingModelList([
        {
          SplitConfigurationId: "",
          Description: "Choose One",
          MarkupRate: null,
          MarkupFee: null,
          MonthlyFee: null,
        },
      ]);
    } else {
      let data = resPricingModels;
      data.unshift({
        SplitConfigurationId: "",
        Description: "Choose One",
        MarkupRate: null,
        MarkupFee: null,
        MonthlyFee: null,
      });

      SetPricingModelList(data);

      const foundItem = data.find(
        (item) => item.SplitConfigurationId === splitConfigurationId
      );

      if (foundItem == null) SetPricingModels(data[0]);
      else SetPricingModels(foundItem);
    }
  }

  async function GetPreOnBoardingLink(dealerId: any) {
    const res = await TargetPayApi(
      `GetPreOnBoardingLink?dealerId=${dealerId}`,
      null,
      "get"
    );

    if (!isEmpty(res))
      setPreOnBoardingToken(baseUrl + "?onboardDealerToken=" + res);
    else setPreOnBoardingToken(res);
  }

  async function CreatePreOnBoardingLink(dealerData: any) {
    let obj: any = new Object();
    obj.DealerId = dealerData.DealerId;
    obj.DmsDealerId = dealerData.DmsDealerId;
    obj.MerchantId = dealerData.MerchantId;
    obj.IndustryCode = IndustryCode.Code;
    obj.SplitConfigurationId = PricingModels.SplitConfigurationId;
    obj.IsPlatformFeeEnabled = IsPlatformFeeEnabled;
    obj.PlatformFeePercentage = PlatformFeePercentage;
    obj.PaymentMethods = DealerData.PaymentMethods;
    obj.CreatedBy = JWTDecode(localStorage.getItem("access_token"), "name");
    obj.ThemeId = paymentLinkThemeId;
    obj.HostUrl = encodeURI(hostUrl);
    let jsonString = JSON.stringify(obj);

    const res = await TargetPayApi("CreatePreOnBoardingLink", jsonString);
    if (!isEmpty(res)) setPreOnBoardingToken(res.url);
    return res;
  }

  function CheckData(): any {
    if (DealerData.Name == null || DealerData.Name == "") {
      alert("Invalid input. Missing Dealer detail");
      return false;
    }
    if (
      IndustryCode == null ||
      IndustryCode == "" ||
      IndustryCode.Description == "Choose One"
    ) {
      alert("Invalid input. Missing Industry code");
      return false;
    }
    if (
      isEmpty(PricingModels.Description) ||
      PricingModels.Description == "ChooseOne"
    ) {
      alert("Invalid input. Missing pricing model");
      return false;
    }
    if (
      IsPlatformFeeEnabled &&
      (isEmpty(PlatformFeePercentage) ||
        PlatformFeePercentage < 3 ||
        PlatformFeePercentage > 3.5)
    ) {
      SetPlatformFeeMsg("Invalid input.");
      return false;
    }

    let PaymentCodes = "";
    for (let i = 0; i < DealerPaymentMethods.length; i++) {
      PaymentCodes += DealerPaymentMethods[i].code + ",";
    }
    DealerData.PaymentMethods = PaymentCodes;

    if (PaymentCodes === "") {
      alert("Invalid input. Missing payment method");
      return false;
    }

    return true;
  }

  const onAddPriceModel = (model: any) => {
    SetPricingModelList([...PricingModelList, model]);

    SetPricingModels(model);
    toggleDrawer(model);
  };

  function GetPricingModelDetail(row: any, key: any) {
    try {
      if (isEmpty(row)) return "";
      let fixedAmount = row.MarkupFee;
      let variablePercentage = row.MarkupRate;
      let monthlyFee = row.MonthlyFee;
      let name = row.Description;

      if (key == "fee") {
        if (isEmpty(fixedAmount)) return "";
        return fixedAmount;
      } else if (key == "monthlyFee") {
        if (isEmpty(monthlyFee)) return "";
        return monthlyFee;
      } else if (key == "percentage") {
        if (isEmpty(variablePercentage)) return "";
        return variablePercentage;
      } else if (key == "name") {
        return (
          name +
          " (Markup: " +
          variablePercentage +
          "%, Fixed: " +
          fixedAmount +
          "$, Monthly: " +
          monthlyFee +
          "$)"
        );
      }
    } catch (error) {
      alert(error);
    }
  }

  async function InitiatePreBoarding(e: any) {
    toast.dismiss();
    setIsProcessing(true);
    if (!CheckData()) {
      setIsProcessing(false);
      return;
    }

    setIsProcessing(true);
    const res = await CreatePreOnBoardingLink(DealerData);

    setIsProcessing(false);
    if (!isEmpty(res)) {
      toast.success("Settings saved successfully");
    }
  }

  return (
    <Box style={{ border: "0px solid red" }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "0px", paddingRight: "0px" }}
      >
        {isEmpty(IndustryCodeData) && (
          <Box>
            Please wait. Processing.....<CircularProgress></CircularProgress>{" "}
          </Box>
        )}
      </Box>

      {!isEmpty(IndustryCodeData) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ padding: "0px", paddingRight: "0px" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px", paddingRight: "0px" }}
          >
            <table style={{ border: "0px solid red" }}>
              <tbody>
                <tr>
                  <td valign="top" style={{ border: "0px solid green" }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ padding: "0px" }}
                    >
                      <Box style={{ display: "block" }}>
                        <Paper>
                          <Box
                            style={{
                              padding: "5px",
                              fontSize: "18px",
                              color: "#3366ff",
                              backgroundColor: "",
                              borderBottom: "1px solid #EAEDED",
                            }}
                          >
                            Setup a Customer
                          </Box>
                          <Box
                            style={{
                              margin: "20px 5px 0px 15px",
                              border: "0px solid red",
                            }}
                          >
                            <Box style={{ fontSize: "small" }}>
                              <Box>
                                <Autocomplete
                                  id="combo-box-demo"
                                  autoHighlight
                                  options={DealersList}
                                  getOptionLabel={(option: any) => {
                                    let label = `${option.Name} - ${option.DmsDealerId}`;
                                    return label;
                                  }}
                                  renderOption={(
                                    props: any,
                                    option: any,
                                    state: any
                                  ) => (
                                    <li
                                      {...props}
                                      style={{
                                        borderBottom: "1px solid #ccc",
                                        padding: "5px",
                                        fontSize: "small",
                                      }}
                                    >
                                      {option.Name} ({option.DmsDealerId} -{" "}
                                      {option.DmsName})
                                      <br />
                                      {GetDealerAddressInfo(option)}
                                      <br />
                                      {GetDealerContactInfo(option)}
                                    </li>
                                  )}
                                  style={{
                                    width: 565,
                                    marginTop: "10px",
                                  }}
                                  className={classes.autoComplete}
                                  fullWidth
                                  onChange={(event, value) =>
                                    OnTextChange(value, "DealerList")
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      variant="standard"
                                      sx={{ fontSize: "small" }}
                                      {...params}
                                      label="Search by Name or Number"
                                      style={{ padding: "0px" }}
                                      fullWidth
                                    />
                                  )}
                                />
                              </Box>

                              <Box style={{ marginTop: "30px" }}>
                                <Autocomplete
                                  id="combo-box-demo"
                                  autoHighlight
                                  disabled={IsDealerOnboarded}
                                  options={IndustryCodeData}
                                  value={IndustryCode}
                                  getOptionLabel={(option: any) => {
                                    let label = option.Code;
                                    if (!isEmpty(option.Code)) {
                                      label = `${option.Code} - ${option.Description}`;
                                    } else {
                                      label = `${option.Description}`;
                                    }
                                    return label;
                                  }}
                                  style={{
                                    width: 565,
                                    marginTop: "10px",
                                  }}
                                  className={classes.autoComplete}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      variant="standard"
                                      sx={{ fontSize: "small" }}
                                      {...params}
                                      label="Industry Code *"
                                      style={{ padding: "0px" }}
                                      fullWidth
                                    />
                                  )}
                                  onChange={(event, value) => {
                                    if (!isEmpty(value)) setIndustryCode(value);
                                    else setIndustryCode(IndustryCodeData[0]);
                                  }}
                                />
                              </Box>
                              <Box style={{ marginTop: "20px" }}>
                                <InputLabel
                                  size="small"
                                  variant="standard"
                                  sx={{ fontSize: "small" }}
                                  id="demo-simple-select-label"
                                >
                                  <>
                                    Pricing Model *
                                    {PricingModelList.length < 1 && (
                                      <CircularProgress
                                        style={{ marginLeft: "10px" }}
                                        size="1rem"
                                      ></CircularProgress>
                                    )}
                                  </>
                                  <Link
                                    style={{
                                      marginLeft: "330px",
                                      visibility: !isEmpty(DealerData.Name)
                                        ? "visible"
                                        : "hidden",
                                    }}
                                    to="#"
                                    onClick={() => toggleDrawer({})}
                                  >
                                    Create Pricing Model
                                  </Link>
                                  {isDrawerOpen && (
                                    <PricingModel
                                      open={isDrawerOpen}
                                      onClose={() => toggleDrawer(DealerData)}
                                      onAddPriceModel={onAddPriceModel}
                                      dealerDetail={DealerData}
                                    />
                                  )}
                                </InputLabel>
                                <Autocomplete
                                  id="combo-box-demo"
                                  autoHighlight
                                  options={PricingModelList}
                                  value={PricingModels}
                                  getOptionLabel={(option: any) => {
                                    let label = "Choose One";
                                    if (
                                      !isEmpty(option.Description) &&
                                      option.Description != "Choose One"
                                    )
                                      label = GetPricingModelDetail(
                                        option,
                                        "name"
                                      );

                                    return label;
                                  }}
                                  style={{
                                    width: 565,
                                    marginTop: "15px",
                                  }}
                                  className={classes.autoComplete}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      variant="standard"
                                      sx={{ fontSize: "small" }}
                                      {...params}
                                      fullWidth
                                    />
                                  )}
                                  onChange={(event, value) => {
                                    if (!isEmpty(value))
                                      SetPricingModels(value);
                                    else SetPricingModels(PricingModelList[0]);
                                  }}
                                />
                              </Box>
                              <Box style={formStyles.fieldSpacer}>
                                <TextField
                                  label="Markup rate (%)"
                                  className={classes.root}
                                  variant="standard"
                                  fullWidth
                                  disabled
                                  id="outlined-required"
                                  value={GetPricingModelDetail(
                                    PricingModels,
                                    "percentage"
                                  )}
                                  inputProps={{
                                    style: { fontSize: "small" },
                                  }}
                                />
                              </Box>
                              <Box style={formStyles.fieldSpacer}>
                                <TextField
                                  label="Markup fee/transaction ($)"
                                  className={classes.root}
                                  variant="standard"
                                  fullWidth
                                  disabled
                                  id="outlined-required"
                                  value={GetPricingModelDetail(
                                    PricingModels,
                                    "fee"
                                  )}
                                  inputProps={{
                                    style: { fontSize: "small" },
                                  }}
                                />
                              </Box>
                              <Box style={formStyles.fieldSpacer}>
                                <TextField
                                  label="Monthly fee ($)"
                                  className={classes.root}
                                  variant="standard"
                                  fullWidth
                                  disabled
                                  id="outlined-required"
                                  value={GetPricingModelDetail(
                                    PricingModels,
                                    "monthlyFee"
                                  )}
                                  inputProps={{
                                    style: { fontSize: "small" },
                                  }}
                                />
                              </Box>
                              <Box style={{ marginTop: "30px" }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      style={{
                                        padding: "2px 5px",
                                        fontSize: "small",
                                      }}
                                      checked={IsPlatformFeeEnabled}
                                      onChange={handleCheckBox}
                                    />
                                  }
                                  label={
                                    <span style={{ fontSize: "small" }}>
                                      Enable Platform fee
                                    </span>
                                  }
                                />
                              </Box>
                              <Box style={{ marginTop: "20px" }}>
                                <TextField
                                  disabled={!IsPlatformFeeEnabled}
                                  label="Platform fee %(3 - 3.5)"
                                  className={classes.root}
                                  variant="standard"
                                  fullWidth
                                  required
                                  id="outlined-required"
                                  name="Platformfee"
                                  value={PlatformFeePercentage}
                                  inputProps={{
                                    style: { fontSize: "small" },
                                  }}
                                  onChange={handleChange}
                                />
                              </Box>
                              {PlatformFeeMsg != "" && (
                                <Box
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {PlatformFeeMsg}
                                </Box>
                              )}
                              <Box style={formStyles.fieldSpacer}>
                                <Autocomplete
                                  style={{ width: "565px" }}
                                  multiple
                                  id="combo-box-demo"
                                  disableCloseOnSelect
                                  options={PaymentMethods}
                                  value={DealerPaymentMethods || []}
                                  getOptionLabel={(option) => option.code}
                                  isOptionEqualToValue={(option, value) =>
                                    option.code === value.code
                                  }
                                  onChange={(event, value) =>
                                    SetDealerPaymentMethods(value)
                                  }
                                  getOptionDisabled={(option) =>
                                    DealerPaymentMethods.some(
                                      (element) =>
                                        element.code === option.code &&
                                        !isEmpty(element.requestedDate)
                                    )
                                  }
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => {
                                    const isDisabled =
                                      DealerPaymentMethods.some(
                                        (element) =>
                                          element.code === option.code &&
                                          !isEmpty(element.requestedDate)
                                      );

                                    return (
                                      <li
                                        {...props}
                                        style={{
                                          pointerEvents: isDisabled
                                            ? "none"
                                            : "auto",
                                        }}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              style={{ padding: "2px 5px" }}
                                              checked={selected}
                                            />
                                          }
                                          label={option.description}
                                        />
                                      </li>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onKeyDown={handleKeyDownEvent}
                                      variant="standard"
                                      label="Payment Methods *"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: "",
                                      }}
                                      inputProps={{
                                        ...params.inputProps,
                                        readOnly: true, // Disable typing in the TextField
                                      }}
                                    />
                                  )}
                                  renderTags={(selected, getTagProps) =>
                                    selected.map((option, index) => {
                                      const isDisabled =
                                        DealerPaymentMethods.some(
                                          (element) =>
                                            element.code === option.code &&
                                            !isEmpty(element.requestedDate)
                                        );

                                      return (
                                        <Chip
                                          key={option.code}
                                          label={option.code}
                                          {...getTagProps({ index })}
                                          disabled={isDisabled}
                                          style={{
                                            pointerEvents: isDisabled
                                              ? "none"
                                              : "auto",
                                            color: isDisabled
                                              ? "gray"
                                              : "inherit",
                                          }}
                                        />
                                      );
                                    })
                                  }
                                />
                              </Box>
                              <Box style={{ marginTop: "20px" }}>
                                <TextField
                                  label="Theme Id"
                                  className={classes.root}
                                  variant="standard"
                                  fullWidth
                                  id="outlined-required"
                                  name="ThemeId"
                                  value={paymentLinkThemeId}
                                  inputProps={{
                                    style: { fontSize: "small" },
                                  }}
                                  onChange={onThemeChanged}
                                />
                                {!isThemeValid && (
                                  <span style={{ color: "red" }}>
                                    Expected format:
                                    XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
                                  </span>
                                )}
                              </Box>
                              <Box style={{ marginTop: "20px" }}>
                                <TextField
                                  label="Host URL"
                                  className={classes.root}
                                  variant="standard"
                                  fullWidth
                                  id="outlined-required"
                                  name="HostUrl"
                                  value={hostUrl}
                                  inputProps={{
                                    style: { fontSize: "small" },
                                  }}
                                  onChange={onHostUrlChanged}
                                />
                                {!isHostUrlValid && (
                                  <span style={{ color: "red" }}>
                                    Invalid URL format
                                  </span>
                                )}
                              </Box>
                              <Box
                                style={{
                                  padding: "10px 10px 10px 0px",
                                  textAlign: "left",
                                }}
                              >
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Button
                                          disabled={
                                            isEmpty(DealerData.Name) ||
                                            isEmpty(IndustryCode.Description) ||
                                            IndustryCode.Description ==
                                              "Choose One" ||
                                            isEmpty(
                                              PricingModels.Description
                                            ) ||
                                            PricingModels.Description ==
                                              "Choose One" ||
                                            (IsPlatformFeeEnabled &&
                                              isEmpty(PlatformFeePercentage)) ||
                                            isEmpty(DealerPaymentMethods) ||
                                            !isThemeValid ||
                                            !isHostUrlValid ||
                                            isProcessing
                                          }
                                          style={formStyles.fieldSpacer}
                                          variant="contained"
                                          onClick={(e) =>
                                            InitiatePreBoarding(e)
                                          }
                                        >
                                          {IsDealerOnboarded
                                            ? "Save"
                                            : "Save & Generate Token"}
                                        </Button>
                                      </td>
                                      <td>
                                        {isProcessing && (
                                          <CircularProgress
                                            size={25}
                                            style={{
                                              marginLeft: "20px",
                                              marginTop: "20px",
                                            }}
                                            color="info"
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Box>
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    </Box>
                  </td>
                </tr>
                <tr
                  style={{
                    visibility:
                      isEmpty(DealerStatus) || DealerStatus != "valid"
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <td valign="top" style={{ border: "0px solid green" }}>
                    {!isEmpty(PreOnBoardingToken) && (
                      <Box
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          style={{
                            border: "1px solid",
                            borderRadius: "5px",
                            marginTop: "20px",
                            width: "585px",
                          }}
                        >
                          <Box
                            style={{
                              padding: "5px",
                              fontSize: "16px",
                              color: "#1976d2",
                              backgroundColor: "",
                              borderBottom: "1px solid #EAEDED",
                            }}
                          >
                            Send the following link to the customer to continue
                            onboarding
                          </Box>
                          <Box
                            style={{
                              margin: "5px",
                              fontSize: "small",
                              wordWrap: "break-word",
                            }}
                          >
                            {PreOnBoardingToken}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      )}
    </Box>
  );
}
