import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Nav from "./nav";
import { Box, CssBaseline, Toolbar } from "@mui/material";

const drawerWidth = 240;

const DashboardLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Determine if only content should be displayed (based on localStorage)
  const showContentOnly = () => localStorage.getItem('showContentOnly') === 'true';

  // Conditional rendering based on `showContentOnly`
  const isContentOnly = showContentOnly();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/*********** Header - Only show if not in content-only mode **********/}
      {
        !isContentOnly && <Header handleDrawerToggle={handleDrawerToggle} />
      }

      {/******** Side Navbar - Only show if not in content-only mode ********/}
      {
        !isContentOnly && <Nav handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
      }

      {/*********** Main ***********/}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* Only add Toolbar if not in content-only mode */}
        {!isContentOnly && <Toolbar />}
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
