import InputMask from "react-input-mask";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckIcon from "@mui/icons-material/Check";
import * as SignalR from "@microsoft/signalr";
import moment from "moment";
import { isEmpty, TitleCase } from "../../utils/Util";
import {
  EverestApi,
  TargetPayApi,
  JWTDecode,
  ValidatePhoneNumber,
  ValidateEmail,
  DealerProfile,
  isValidUSPhoneNo,
  formatUSPhoneNo,
  GetDealerSettings,
} from "../../utils/UtilTarget";
import { useEffect, useState } from "react";
import * as React from "react";
import axios from "axios";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Callback } from "../../utils/Util";
import PaymentContainer from "./AdyenPaymentDropIn";
import AdyenTransactions from "./AdyenTransactions";
import { toast } from "react-toastify";
import { FormStyleCollection as formStyles } from "../../styles/form";
import { StyleCreator } from "../../helpers/stylecreator";
import { CardType, PaymentStatus } from "../../types/enums";
let dollarUSLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

let paymentToken = "";
let paymentProcessed = false;
let paymentStatus = "";
export default function Payment() {
  const [signalRconnection, setSignalRConnection] = useState<any>(null);
  const [token, setToken] = React.useState("");
  const [terminals, setTerminals] = React.useState<any>([]);
  const [paymentData, setPaymentData] = React.useState<any>([]);
  const [VaultedCards, SetVaultedCards] = React.useState<any>(null);
  const [VaultedCardId, SetVaultedCardId] = React.useState("");
  const [terminal, setTerminal] = useState("0");
  const [counter, setCounter] = React.useState<any>(0);
  const [isProcessing, setIsProcessing] = React.useState<any>(true);
  const [tabValue, setTabValue] = React.useState(SetDefaultTab());
  const classes = StyleCreator.makeStyles(formStyles.all);
  const [PaymentLinkResponse, SetPaymentLinkResponse] =
    React.useState<any>(null);
  const [paymentDone, setPaymentDone] = React.useState<any>(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [PlatformFeeFlag, SetPlatformFeeFlag] = React.useState<any>(false);
  const [manualPaymentStatus, SetManualPaymentStatus] = React.useState<any>("");
  const [EmergepayIsSandBox, setEmergepayIsSandBox] = React.useState(-1);
  const styleButton = {
    minWidth: "200px",
  };
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  function SetDefaultTab() {
    let isSaveCardAction = window.location.href.toLowerCase().includes("savecard");
    if (isSaveCardAction) {
      return "SaveCardTerminal";
    }
    var urlParams = new URLSearchParams(window.location.search);
    let tabs = urlParams.get("tabs");
    if (!isEmpty(tabs)) {
      const myArray = tabs.split(";");
      for (let i = 0; i < myArray.length; i++) {
        return TitleCase(myArray[i]);
      }
      return false;
    }

    return "Terminal";
  }

  useEffect(() => {
    const validate = async () => {
      await ValidateToken();
      setIsTokenValidated(true);
    };
    validate();
  }, []);

  useEffect(() => {
    if (isTokenValidated) {
      let obj: any = new Object();
      obj.Type = "TabChange";
      obj.Tab = tabValue;
      let jsonString = JSON.stringify(obj);
      Callback(jsonString, paymentData.hostUrl);
    }
  }, [tabValue]);

  useEffect(() => {
    window.addEventListener("message", EmergePayResponse);
    paymentToken = "";
    document.title = "New Payment";
    paymentProcessed = false;
    ValidateToken();
  }, []);

  const EmergePayResponse = (event: any) => {
    if (isEmpty(event.data) || isEmpty(event.data.isApproved)) return;

    try {
      let urlParams = new URLSearchParams(window.location.search);
      event.data.isMobile = urlParams.get("ismobile");
      Callback(event.data, paymentData.hostUrl);
    } catch (error) {
      console.error("Error: Payment.EmergePayResponse: " + error);
    }
  };

  function ShowTabs(): any {
    var urlParams = new URLSearchParams(window.location.search);
    let tabs = urlParams.get("tabs");
    if (!isEmpty(tabs)) {
      const myArray = tabs.split(";");
      return myArray.length > 1;
    }
    return true;
  }

  function CheckTab(tab: string): any {
    let isSaveCardAction = window.location.href.toLowerCase().includes("savecard");
    if (isSaveCardAction === true) {
      if (tab.toLowerCase() === "savecard" || tab.toLowerCase() === "savecardterminal") return true;
    }

    else {
      let transactionType = JWTDecode(token, "TransactionType");
      if (transactionType == "CreditReturn") {
        if (tab == "Terminal") return true;
        return false;
      }

      var urlParams = new URLSearchParams(window.location.search);
      let tabs = urlParams.get("tabs");
      if (tab.toLowerCase() === "savecard" || tab.toLowerCase() == "savecardterminal") return false;
      if (!isEmpty(tabs)) {
        const myArray = tabs.split(";");
        for (let i = 0; i < myArray.length; i++) {
          if (myArray[i].toLowerCase() == tab.toLowerCase())
          return true;
        }
        return false;
      }
      return true;
    }
  }

  async function makeSignalRConnection(referenceNumber: any) {
    const uid = referenceNumber;
    const dealerId = "10038";
    const negotiateRequestBody = { uid, dealerId };
    const negotiateRequestHeader = { headers: { "x-ms-signalr-userid": uid } };
    try {
      let url = `https://azurefunctionkg.azurewebsites.net/api/negotiate`;
      let negotiateResponse: any = await axios.post(
        url,
        negotiateRequestBody,
        negotiateRequestHeader
      );
      let signalRConnection: any = new SignalR.HubConnectionBuilder()
        .withUrl(negotiateResponse.data.url, {
          accessTokenFactory: () => negotiateResponse.data.accessToken,
        })
        .withAutomaticReconnect()
        .build();
      setSignalRConnection(signalRConnection);
    } catch (error) {
      console.error("Error: SignalR negotiation failed: ", error);
    }
  }
  useEffect(() => {
    if (signalRconnection) {
      if (signalRconnection._connectionState != "Connected") {
        console.log(
          "SignalR connectionState.0: " + signalRconnection._connectionState
        );
        signalRconnection
          .start()
          .then(() => {
            console.log(
              "SignalR connectionState.1: " + signalRconnection._connectionState
            );
            signalRconnection.on("newMessage", (message) => {
              CheckPaymentResponse(message.text);
            });
          })
          .catch((error) => console.log(error));
      }
    }
  }, [signalRconnection]);

  function CheckPaymentResponse(paymentContent: any) {
    try {
      const payment = JSON.parse(paymentContent);
      if (payment.notificationItems.length > 0) {
        paymentData.status =
          payment.notificationItems[0].NotificationRequestItem.success == "true"
            ? "Success"
            : "Failed";
        CallBackPaymentResponse(
          paymentData.status,
          "Payment",
          payment.notificationItems[0].NotificationRequestItem.reason,
          payment
        );
        setCounter(counter + 1);
      }
    } catch (error) {
      alert("CheckPaymentResponse.Error: " + error);
    }
  }

  function Terminals() {
    try {
      if (terminals == null || terminals.data == null) return [];
      return terminals.data;
    } catch (error) {
      return [];
    }
  }

  async function ValidateToken() {
    var urlParams = new URLSearchParams(window.location.search);
    let access_token = urlParams.get("access_token");
    let refresh_token = urlParams.get("refresh_token");
    if (!isEmpty(access_token))
      localStorage.setItem("access_token", access_token);
    if (!isEmpty(refresh_token))
      localStorage.setItem("refresh_token", refresh_token);

    let token = urlParams.get("payment_token");
    if (isEmpty(token)) token = urlParams.get("token");
    if (isEmpty(token) || token.length < 5) {
      setErrorMessage("Invalid/expired token");
      setIsProcessing(false);
      return;
    }

    paymentToken = token;
    setToken(token);
    setIsProcessing(true);
    if (token.length > 50) {

      let nonce = JWTDecode(token, "Nonce");
      if (!isEmpty(nonce)) {
        nonce = nonce.split("").reverse().join("");
        sessionStorage.setItem("nonce", nonce);
      }
      
      const res = await TargetPayApi("ValidateAdyenPaymentToken", token);
      if (isEmpty(res)) {
        setErrorMessage("invalid/expired access token");
        setIsProcessing(false);
        return;
      }

      setIsProcessing(false);
      if (!isEmpty(res)) {
        await GetDealerSettings(res.dealerId);

        SetPlatformFeeFlag(localStorage.getItem("platformFee_flag_enabled"));

        if (!isEmpty(res.status) && res.status.includes("Pending"))
          res.status = null;
        setPaymentData(res);
        if (res.void) setTabValue("Payments");
        if (!paymentProcessed)
          paymentProcessed = !isEmpty(res.pspReference) || !isEmpty(res.status);
        if (paymentProcessed) paymentStatus = res.status;
        if (paymentStatus == PaymentStatus.Success){
          paymentStatus = PaymentStatus.Authorised;
          SetManualPaymentStatus(paymentStatus);
        }
        if (!isEmpty(res.dealerInfo)) {
          var r = await TargetPayApi(
            "StoreTerminals",
            { dealerId: res.dealerId },
            "get"
          );
          setTerminals(r);
          let POIID = ""; //JWTDecode(token, "POIID");
          if (isEmpty(POIID)) {
            let DealerInfo = JWTDecode(token, "DealerInfo");
            const obj = JSON.parse(DealerInfo);
            if (!isEmpty(obj.UserDefaultPOIID)) POIID = obj.UserDefaultPOIID;
          }
          if (isEmpty(POIID)) POIID = JWTDecode(token, "POIID");
          if (!isEmpty(POIID)) {
            if (r != null) {
              if (r.data != null) {
                let item = r.data.find(({ id }) => id === POIID);
                if (isEmpty(item))
                  item = r.data.find(
                    ({ terminalName }) => terminalName === POIID
                  );
                if (!isEmpty(item)) setTerminal(POIID);
              }
            }
          }
          if (isEmpty(res.pspReference) && isEmpty(res.status)) {
            makeSignalRConnection(res.paymentId);
          }
        }
        if (!isEmpty(res)) {
          GetCustomerToken(res.dealerId, res.customerId);
        }

        if (!isEmpty(res.customerPhone)) {
          if (!isValidUSPhoneNo(res.customerPhone)) {
            res.customerPhone = formatUSPhoneNo(res.customerPhone);
          }
        }
      }
    }
    await GetEmergePaySettings(token);
  }

  async function GetEmergePaySettings(token) {
    if (token.length > 50) return;
    let obj: any = new Object();
    obj.TransactionToken = token;
    const res = await EverestApi(
      "Gravity.GetTransactionDealerId",
      JSON.stringify(obj)
    );
    if (!isEmpty(res)) {
      let dealerId = res.DealerId;
      const res1 = await TargetPayApi(
        `GetDealerCredential/${dealerId}`,
        null,
        "get",
        "EmergePay"
      );
      setEmergepayIsSandBox(res1.isSandbox ? 1 : 0);
    }
  }

  function onManualPaymentStarted() {
    setIsProcessing(true);
  }

  function onManualPaymentCompleted(response, error) {
    setPaymentDone(true);
    if (!isEmpty(error)) {
      CallBackPaymentResponse(
        "failure",
        "",
        "",
        null,
        "",
        null,
        "",
        null,
        "",
        error
      );
    }
  }

  async function CallBackPaymentResponse(
    status: string,
    responseType: string = "Payment",
    reason: string = "",
    payment: any = null,
    cardSummary = "",
    expiryDate = "",
    authCode = "",
    amount = 0,
    paymentMethod = "",
    errorMessage = "",
    paymentPageId: string = "",
    fundingSource = ""
  ) {
    paymentStatus = status;
    SetManualPaymentStatus(paymentStatus)
    //for saving card only(0 payment transaction) when terminal response is received, not a webhook
    if (cardSummary && cardSummary !== ""
      && paymentData.requestedAmount === 0 && payment === null) {
      return;
    }
    paymentData.status = status;
    if (isEmpty(amount)) amount = 0;
    if (!isEmpty(payment)) {
      if (!isEmpty(payment.notificationItems[0].NotificationRequestItem)) {
        if (
          !isEmpty(
            payment.notificationItems[0].NotificationRequestItem.additionalData
          )
        ) {
          cardSummary =
            payment.notificationItems[0].NotificationRequestItem.additionalData
              .cardSummary;
          expiryDate =
            payment.notificationItems[0].NotificationRequestItem.additionalData
              .expiryDate;
          authCode =
            payment.notificationItems[0].NotificationRequestItem.additionalData
              .authCode;
        }
        if (
          !isEmpty(payment.notificationItems[0].NotificationRequestItem.amount)
        ) {
          amount =
            payment.notificationItems[0].NotificationRequestItem.amount.value /
            100;
        }
        if (
          !isEmpty(
            payment.notificationItems[0].NotificationRequestItem.paymentMethod
          )
        ) {
          paymentMethod =
            payment.notificationItems[0].NotificationRequestItem.paymentMethod;
        }
      }
    }
    let transactionType = getTransactionType(payment, fundingSource);
    let cashierId = JWTDecode(token, "CashierID");
    paymentProcessed = true;
    switch (status.toLowerCase()) {
      case "success":
      case "authorised":
        paymentStatus = PaymentStatus.Authorised;
        SetManualPaymentStatus(paymentStatus);
        break;
      case "failure":
        paymentStatus = PaymentStatus.Failed;
        paymentData.status = PaymentStatus.Failed;
        SetManualPaymentStatus(paymentStatus);
        break;
    }

    const getVaultedCardIdSearchCriteria = {
      dealerId: paymentData.dealerId,
      customerId: paymentData.customerId,
      cardLastFour: cardSummary
    };

    let vaultedCardResponse = "";

    //for saving card only(0 payment transaction)
    if (cardSummary && cardSummary !== ""
      && paymentData.requestedAmount === 0) {
        await new Promise(resolve => setTimeout(resolve, 3000));
        vaultedCardResponse = await TargetPayApi(
          "GetVaultedCardId",
          getVaultedCardIdSearchCriteria,
          "Get",
          "VaultCard",
          true
        );
      }

    let obj: any = new Object();
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (guidRegex.test(vaultedCardResponse)) {
      obj.VaultedCardId = vaultedCardResponse;
    }
    obj.dealerId = paymentData.dealerId;
    obj.paymentId = paymentData.paymentId;
    obj.accountCardType = paymentMethod;
    obj.accountEntryMethod = "Auto";
    obj.accountExpiryDate = expiryDate;
    obj.amount = paymentData.requestedAmount;
    obj.amountBalance = "";
    obj.amountProcessed = paymentData.requestedAmount + paymentData.platformFee;
    obj.amountTaxed = paymentData.salesTaxAmount;
    obj.amountTipped = "0";
    obj.approvalNumberResult = authCode;
    obj.avsResponseCode = "NA";
    obj.avsResponseText = "NA";
    obj.batchNumber = "";
    obj.billingName = paymentData.customerName;
    obj.cashier = cashierId;
    obj.consumerSurchargeFee = paymentData.platformFee;
    obj.createdOn = new Date().toUTCString();
    obj.customerId = paymentData.customerId;
    obj.cvvResponseCode = "";
    obj.cvvResponseText = "Match";
    obj.externalTransactionId = paymentData.paymentId;
    obj.isPartialApproval = false;
    obj.maskedAccount = `************${cardSummary}`;
    obj.resultMessage = paymentStatus;
    obj.resultStatus = paymentStatus == PaymentStatus.Authorised ? "true" : "false";
    obj.transactionReference = paymentData.invoiceId;
    obj.transactionType = transactionType;
    obj.uniqueTransId = paymentData.paymentId;
    obj.errorMessage = errorMessage;

    let res: any = new Object();
    res.isApproved = paymentStatus == PaymentStatus.Authorised ? "true" : "false";
    res.transactionResponse = obj;
    let urlParams = new URLSearchParams(window.location.search);
    res.isMobile = urlParams.get("ismobile");
    res.paymentPageId = paymentPageId;

    let jsonString = JSON.stringify(res);
    Callback(res, paymentData.hostUrl);

    SavePaymentResponse(jsonString);
    toast.dismiss();
    if (paymentStatus == PaymentStatus.Authorised)
      toast.success("Status: Authorized", {
        position: toast.POSITION.TOP_CENTER,
      });
    if (paymentStatus == PaymentStatus.Failed && isEmpty(paymentPageId)) {
      if (isEmpty(errorMessage)) errorMessage = paymentData.status;
      toast.error("Status: " + errorMessage, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: true,
      });
    }
    setCounter(counter + 1);
  }

  function getTransactionType(payment, fundingSource){
    const isDebit = (value: string | undefined): boolean => !isEmpty(value) && value.toLowerCase().includes('debit');
    if(!isEmpty(fundingSource) && isDebit(fundingSource)){
      return CardType.DebitSale;
    }
    if (isEmpty(payment)) {
      return JWTDecode(token, "TransactionType");
    }
    const additionalData = payment.notificationItems[0].NotificationRequestItem.additionalData;

    if (
      isDebit(additionalData["fundingSource"]) ||
      isDebit(additionalData["checkout.cardAddedBrand"])
    ) {
        return CardType.DebitSale;
    }
    return JWTDecode(token, "TransactionType");
  }
  function isMobile() {
    let urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("ismobile") == "true";
  }

  function CheckPayment(res: any) {
    const obj = JSON.parse(res);
    const myArray = obj.AdditionalResponse.split("&");
    let cardSummary = "",
      cardBin = "",
      expiryMonth = "",
      expiryYear = "",
      paymentMethod = "",
      fundingSource = "",
      posAuthAmountValue = 0;
    for (let i = 0; i <= myArray.length - 1; i++) {
      let values = myArray[i].split("=");
      if (values.length == 2) {
        if (values[0] == "acquirerResponseCode") {
          if (values[1] != "APPROVED") break;
        }
        if (values[0] == "cardSummary") cardSummary = values[1];
        if (values[0] == "cardBin") cardBin = values[1];
        if (values[0] == "expiryMonth") expiryMonth = values[1];
        if (values[0] == "expiryYear") expiryYear = values[1];
        if (values[0] == "posAuthAmountValue")
          posAuthAmountValue = values[1] / 100;
        if (values[0] == "paymentMethod") paymentMethod = values[1];
        if (values[0]== "fundingSource")  fundingSource = values[1];
      }
    }
    paymentData.status = obj.Result;
    if (cardSummary != "" || obj.Result == "Failure")
      CallBackPaymentResponse(
        obj.Result,
        "",
        "",
        null,
        cardSummary,
        expiryMonth + "/" + expiryYear,
        cardBin,
        posAuthAmountValue,
        paymentMethod,
        "",
        "",
        fundingSource
      );
  }

  async function ProcessPayment(isSaveTab = false) {
    
    let paymentInitEvent = {
      paymentInit: true,
      paymentId: paymentData.paymentId,
      dealerId: paymentData.dealerId
    };
    Callback(JSON.stringify(paymentInitEvent), paymentData.hostUrl);
    
    if (isSaveTab === true) paymentData.SaveCard = true
    toast.dismiss();
    setIsProcessing(true);
    paymentData.POIID = terminal;
    let res = await TargetPayApi(
      "AdyenTerminalPayment",
      paymentData,
      "post",
      "PerseusPay",
      true
    );
    if (!isEmpty(res)) {
      let obj = JSON.parse(res);
      if (!isEmpty(obj.Error)) {
        CallBackPaymentResponse(
          "failure",
          "",
          "",
          null,
          "",
          null,
          "",
          null,
          "",
          obj.Error
        );
        return;
      }
      CheckPayment(res);
      setPaymentDone(true);
    }
  }

  async function SavePaymentResponse(paymentResponse) {
    toast.dismiss();
    setIsProcessing(true);

    let res = await TargetPayApi("SavePaymentResponse", paymentResponse);

    setIsProcessing(false);

    if (!isEmpty(res)) {
      if (!(res.statusCode == "OK")) {
        toast.error("Error: ", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });
        return;
      }
    }
  }

  async function GetCustomerToken(dealerId, customerId) {
    let obj: any = new Object();
    let objBody: any = new Object();

    obj.query = "PerseusPay.VaultedCards";
    objBody.DealerId = dealerId;
    objBody.CustomerId = customerId;

    obj.queryBody = JSON.stringify(objBody);

    const response = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );
    if (!isEmpty(response) && response.length > 0) {
      SetVaultedCards(response);
      SetVaultedCardId(response[0].VaultedCardId);
    }
  }

  function CustomerPaymentToken() {
    return VaultedCards[0];
  }

  function AmountLabel() {
    let label = "Amount";
    if (JWTDecode(token, "TransactionTypeId") === "1") label = "Credit Return";
    return label;
  }

  function Header({ tab = "Default" }) {
    return (
      <Box>
        {!isEmpty(paymentData.status) && (
          <Box>
            <h5>
              Status:{" "}
              {paymentData.status &&
                paymentData.status.toLowerCase() == "authorised"
                ? "Authorized"
                : paymentData.status}
            </h5>
          </Box>
        )}
        {tab !== "SaveCard" && tab !== "SaveCardTerminal" && (
          <>
            <Box>Order: {paymentData.invoiceId}</Box>
            <Box>Customer: {paymentData.customerName}</Box>
            <Box>
              {AmountLabel()}: {dollarUSLocale.format(paymentData.requestedAmount)}
            </Box>
            {PlatformFeeFlag === "true" && (
              <>
                <Box>
                  Platform fee: {dollarUSLocale.format(paymentData.platformFee)}
                </Box>
                <Box>Total: {dollarUSLocale.format(paymentData.totalAmount)}</Box>
              </>
            )}
          </>
        )}
      </Box>
    );
  }

  function SaveCard() {
    return (
      <span className={classes.defaultFont}>
        {paymentData.saveCard &&
          JWTDecode(token, "TransactionType") != "CreditReturn" &&
          (
            <span>
              Save Card&nbsp;&nbsp;<>&#x2713;</>
            </span>
          )}
      </span>

      //   <FormControlLabel style={{ margin: "0px" }}
      //     control={
      //       <Checkbox
      //         disabled={true}
      //         checked={paymentData.saveCard}
      //         name="AllowTextToPay"
      //       />
      //     }
      //     label="Save Card"
      //   />
      //
    );
  }

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  async function sendLink() {
    let phone = paymentData.customerPhone;
    if (!isEmpty(phone)) {
      if (!isValidUSPhoneNo(phone)) {
        phone = formatUSPhoneNo(phone);
      }
    }
    setIsProcessing(true);
    let obj: any = new Object();
    obj.sendType = "*";
    obj.token = paymentToken;
    obj.CustomerCell = phone;
    if (ValidateEmail(paymentData.customerEmail))
      obj.CustomerEmail = paymentData.customerEmail;
    obj.DealerId = paymentData.dealerId;
    obj.CustomerId = paymentData.customerId;
    obj.PaymentObject = PaymentLinkResponse;
    obj.CashierID = JWTDecode(paymentToken, "CashierID");
    obj.CustomerName = JWTDecode(paymentToken, 'CustomerName');
    // obj.Memo = JWTDecode(paymentToken, 'Memo');
    // obj.MoreInfo = JWTDecode(paymentToken, 'MoreInfo');
    let jsonString = JSON.stringify(obj);
    const res = await TargetPayApi("AdyenPaymentLink", jsonString);
    if (!res.isRequestSucceeded) {
      toast.dismiss();
      toast.error("Error: " + res.response, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: true,
      });
    } else if (!isEmpty(res) || !isEmpty(res.paymentObject)) {
      SetPaymentLinkResponse(res.paymentObject);
      // makeSignalRConnection(paymentData.paymentId);
      CallBackPaymentResponse(
        "Link Sent",
        "",
        "",
        null,
        "",
        null,
        "",
        null,
        "",
        "",
        res.id
      );

      return;
    }
    setIsProcessing(false);
  }

  async function SendMessage(sendType: string) {
    let obj: any = new Object();
    obj.sendType = sendType;
    obj.token = paymentToken;
    obj.CustomerCell = paymentData.customerPhone;
    obj.CustomerEmail = paymentData.customerEmail;
    obj.DealerId = paymentData.dealerId;
    obj.CustomerId = paymentData.customerId;
    obj.PaymentObject = PaymentLinkResponse;
    let jsonString = JSON.stringify(obj);
    const res = await TargetPayApi("AdyenPaymentLink", jsonString);
    setIsProcessing(false);
    if (!isEmpty(res) || !isEmpty(res.PaymentObject)) {
      SetPaymentLinkResponse(res.PaymentObject);
      makeSignalRConnection(paymentData.paymentId);
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  function GetTokenLabel(): any {
    if (!isEmpty(VaultedCards) && VaultedCards.length > 0)
      return `${VaultedCards.length}`;
  }

  async function ProcessTokenPayment() {
    let obj: any = new Object();
    obj.VaultedCardId = VaultedCardId;
    obj.token = paymentToken;
    let jsonString = JSON.stringify(obj);
    setIsProcessing(true);
    const res = await TargetPayApi("ProcessAdyenVaultedPayment", jsonString);
    setIsProcessing(false);
    if (!isEmpty(res)) {
      if (res.hasOwnProperty("errorCode") && !isEmpty(res.errorCode)) {
        CallBackPaymentResponse(
          "failure",
          "",
          "",
          null,
          "",
          null,
          "",
          null,
          "",
          res.message
        );
        return;
      }
      let Reason = "";
      if (res.hasOwnProperty("refusalReason")) Reason = res.refusalReason;
      setPaymentDone(true);
      CallBackPaymentResponse(res.resultCode, "Payment", Reason);
    }
  }
  function OnTextChange(value: any, key: any) {
    paymentData[key] = value;
    setCounter(counter + 1);
  }

  function onTerminalChange(e) {
    setTerminal(e);
    setCounter(counter + 1);
  }

  function onVoidPayment(e) {
    setPaymentDone(true);
  }

  function CheckActiveTermininalInMin(row: any): any {
    if (isEmpty(row.lastActivityAt)) return 9999;
    var date1: any = Date.parse(row.lastActivityAt);
    var data2: any = new Date();
    var diffMs = data2 - date1; // milliseconds between now & Christmas
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffMins;
  }

  function CheckActiveTerminal(row: any): any {
    // if (isEmpty(row.lastActivityAt)) return "";
    // var date1: any = Date.parse(row.lastActivityAt);
    // var data2: any = new Date();
    // var diffMs = (data2 - date1); // milliseconds between now & Christmas
    // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let diffMins = CheckActiveTermininalInMin(row);
    let res = `Now`;
    if (diffMins > 24 * 60) return "";
    if (diffMins > 0) res = `${diffMins}`;
    if (diffMins < 2) return `(Active)`;
    if (diffMins < 60) return `(Active ${res}m)`;
    return "";
  }

  const renderManualPaymentStatus = () => {
    if (manualPaymentStatus === PaymentStatus.Success || manualPaymentStatus === PaymentStatus.Authorised) {
      return <span style={{ color: "green" }}>Payment successfully processed...</span>;
    } else if (manualPaymentStatus === PaymentStatus.LinkSent) {
      return <span style={{ color: "green" }}>Payment Link Sent...</span>;
    } else if (manualPaymentStatus === PaymentStatus.Failed || !isEmpty(manualPaymentStatus)) {
      return <span style={{ color: "red" }}>Payment failed...</span>;
    } 
    return null; // Return null if no conditions are met
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ padding: "10px" }}
    >
      {isEmpty(paymentToken) && !isProcessing && (
        <Box
          style={{
            border: "1px solid silver",
            padding: "5px 10px",
            marginTop: "20px",
          }}
        >
          <h3>Invalid or missing payment token...</h3>
        </Box>
      )}
      {!isEmpty(errorMessage) && (
        <Box
          style={{
            border: "1px solid silver",
            padding: "5px 10px",
            marginTop: "20px",
          }}
        >
          <h3>{errorMessage}</h3>
        </Box>
      )}

      {isEmpty(paymentData) &&
        !isEmpty(paymentToken) &&
        paymentData.length > 50 && (
          <Box
            style={{
              border: "1px solid silver",
              padding: "5px 10px",
              marginTop: "20px",
            }}
          >
            <h2>Invalid/Expired payment request</h2>
          </Box>
        )}
      {!isEmpty(token) && EmergepayIsSandBox > -1 && token.length < 50 && (
        <Box>
          <iframe
            style={{ border: "0px solid red", width: "700px", height: "570px" }}
            title="static_html"
            src={`emergePay.html?isSandBox=${EmergepayIsSandBox}&transactionToken=${token}`}
          ></iframe>
        </Box>
      )}

      {!isEmpty(paymentData) && token.length > 50 && (
        <Box>
          <Tabs
            style={{ display: ShowTabs() ? "" : "none" }}
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {CheckTab("SaveCard") && (
              <Tab
                label="Save Card"
                value="SaveCard"
              />
            )}
            
            {CheckTab("Terminal") && (
              <Tab
                disabled={paymentData.void || isProcessing}
                label="Terminal"
                value="Terminal"
              />
            )}
             {CheckTab("SaveCardTerminal") && (
              <Tab
                disabled={paymentData.void || isProcessing}
                label="Save Card Terminal"
                value="SaveCardTerminal"
              />
            )}
            {CheckTab("Manual") && (
              <Tab
                disabled={paymentData.void || isProcessing}
                label="Manual"
                value="Manual"
              />
            )}
            {CheckTab("Sendlink") && (
              <Tab
                disabled={paymentData.void || isProcessing}
                label="Link to pay"
                value="Sendlink"
              />
            )}
            {CheckTab("Token") && (
              <Tab
                disabled={paymentData.void || isProcessing}
                label={
                  <Badge badgeContent={GetTokenLabel()} color="primary">
                    Saved Cards&nbsp;&nbsp;
                  </Badge>
                }
                value="Token"
              />
            )}
            {CheckTab("Payments") &&
              paymentData &&
              !isEmpty(paymentData.transactions) &&
              paymentData.transactions > 0 && (
                <Tab
                  disabled={isProcessing}
                  label={
                    <Badge
                      badgeContent={paymentData.transactions}
                      color="primary"
                    >
                      Payments&nbsp;&nbsp;
                    </Badge>
                  }
                  value="Payments"
                />
              )}
          </Tabs>
          <Box style={{ marginTop: "10px" }}>
            {/* AdyenTransactions */}
            {tabValue == "SaveCard" && (
              <Box>
                {paymentProcessed && (
                  <Box className={classes.paymentBox}>
                    <Header tab="SaveCard"></Header>
                    {(paymentStatus == "Success" ||
                      paymentStatus == "Authorised") && (
                        <h4>
                          <span style={{ color: "green" }}>
                            Card Saved...
                          </span>
                        </h4>
                      )}
                    {(paymentStatus == "Failed" ||
                      paymentStatus != "Authorised") &&
                      !isEmpty(manualPaymentStatus) && (
                        <h4>
                          <span style={{ color: "red" }}>
                            Saving Card failed...
                          </span>
                        </h4>
                      )}
                  </Box>
                )}
                {!paymentProcessed && (
                  <Box className={classes.paymentBoxManual}>
                    <Box>
                      <PaymentContainer
                        token={paymentToken}
                        onPaymentStarted={onManualPaymentStarted}
                        onPaymentCompleted={onManualPaymentCompleted}
                        saveCard={true}
                      />
                    </Box>
                  </Box>
                )}
              </Box>

            )}
            {tabValue == "Payments" && (
              <Box className={classes.paymentBox}>
                <Header></Header>
                <AdyenTransactions
                  paymentData={paymentData}
                  terminals={Terminals()}
                  terminal={terminal}
                  onTerminalChange={onTerminalChange}
                  onVoidPayment={onVoidPayment}
                ></AdyenTransactions>
              </Box>
            )}
            {tabValue == "Token" && (
              <Box className={classes.paymentBox}>
                <Box>
                  <Header></Header>
                </Box>

                {VaultedCards && (
                  <Box
                    style={{
                      paddingTop: "10px",
                      borderTop: "1px solid silver",
                    }}
                  >
                    {VaultedCards.map((row: any, index: any) => {
                      return (
                        <Box
                          style={{
                            borderBottom: "1px solid #E5E7E9",
                            marginBottom: "5px",
                            padding: "5px 10px",
                            cursor: "pointer",
                            backgroundColor:
                              row.VaultedCardId == VaultedCardId
                                ? "#D4E6F1"
                                : "",
                          }}
                          onClick={(e) => SetVaultedCardId(row.VaultedCardId)}
                          key={index}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <Checkbox
                                    checked={row.VaultedCardId == VaultedCardId}
                                  />
                                </td>
                                <td>
                                  <Box>Card Type: {row.CardType}</Box>
                                  <Box>
                                    Card Number: *****{row.CardNumber} (
                                    {moment
                                      .utc(row.ExpiryDate)
                                      .local()
                                      .format("MM/YYYY")}
                                    )
                                  </Box>
                                  <Box>
                                    Date Setup:{" "}
                                    {moment
                                      .utc(row.DateSetup)
                                      .local()
                                      .format("M/D/YYYY h:mm A")}
                                  </Box>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Box>
                      );
                    })}
                    <Box
                      style={{
                        margin: "10px 0px",
                        textAlign: "right",
                        marginRight: "10px",
                      }}
                    >
                      <Button
                        disabled={isProcessing || paymentProcessed}
                        onClick={ProcessTokenPayment}
                        variant="contained"
                      >
                        Process Payment{" "}
                        {dollarUSLocale.format(paymentData.totalAmount)}
                      </Button>
                    </Box>
                  </Box>
                )}
                {!VaultedCards && (
                  <Box style={{ padding: "10px" }}>
                    <h4>No card saved</h4>
                  </Box>
                )}
              </Box>
            )}
            {tabValue == "Sendlink" && (
              // <Paper>
              <Box className={classes.paymentBox}>
                <Header></Header>
                <Box style={formStyles.fieldSpacer}>
                  <TextField
                    size="small"
                    label="Email"
                    style={{ marginTop: "5px" }}
                    fullWidth
                    id="outlined-required"
                    value={paymentData.customerEmail}
                    onChange={(e) =>
                      OnTextChange(e.target.value, "customerEmail")
                    }
                    InputLabelProps={{ className: classes.defaultFont }}
                  />
                </Box>
                <Box style={formStyles.fieldSpacer}>
                  <InputMask
                    mask="+1 (999) 999 9999"
                    alwaysShowMask={false}
                    value={paymentData.customerPhone}
                    onChange={(e) =>
                      OnTextChange(e.target.value, "customerPhone")
                    }
                  >
                    {() => (
                      <TextField
                        size="small"
                        autoFocus
                        label="Cell Phone"
                        fullWidth
                        id="outlined-required"
                        InputLabelProps={{ className: classes.defaultFont }}
                      />
                    )}
                  </InputMask>
                </Box>
                <Box>
                  <Button
                    style={{ marginTop: "20px" }}
                    fullWidth
                    onClick={(e) => sendLink()}
                    disabled={
                      (ValidatePhoneNumber(paymentData.customerPhone) ||
                        ValidateEmail(paymentData.customerEmail)
                        ? false
                        : true) ||
                      isProcessing ||
                      paymentProcessed
                    }
                    variant="contained"
                  >
                    Send Link
                  </Button>
                </Box>
                <Box>
                  {PaymentLinkResponse && (
                    <Box style={{ marginTop: "15px" }}>
                      <Box>Payment Link: {PaymentLinkResponse.url}</Box>
                      <Box>
                        Expires At:{" "}
                        {moment
                          .utc(PaymentLinkResponse.expiresAt)
                          .local()
                          .format("M/D/YYYY h:mm A")}
                      </Box>
                      <Box>Payment Id: {PaymentLinkResponse.id}</Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {tabValue == "Manual" && (
              <Box>
                {paymentProcessed && (
                  <Box className={classes.paymentBox}>
                    <Header></Header>
                      <h4>
                        {renderManualPaymentStatus()}
                      </h4>
                  </Box>
                )}
                {!paymentProcessed && (
                  <Box className={classes.paymentBoxManual}>
                    <Box>
                      <PaymentContainer
                        token={paymentToken}
                        onPaymentStarted={onManualPaymentStarted}
                        onPaymentCompleted={onManualPaymentCompleted}
                        saveCard={paymentData.saveCard}
                      />
                    </Box>

                    <Box style={{ textAlign: "right" }}>
                      <SaveCard></SaveCard>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {tabValue == "Terminal" && (
              <Box>
                <Box
                  className={classes.paymentBox}
                  style={{ minWidth: !isMobile() ? "400px" : "100px" }}
                >
                  <Box style={{ border: "0px solid red" }}>
                    <Box>
                      <Header></Header>
                    </Box>

                    <Box style={{ marginTop: "15px" }}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="filter-type-label">
                          Payment Terminal
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="filter-type-label"
                          id="filter-type-select"
                          value={terminal}
                          onChange={(e) =>
                            setTerminal(e.target.value as string)
                          }
                          label="Payment Terminal 123"
                        >
                          <MenuItem value="0">Choose One</MenuItem>
                          {Terminals().map((row: any, index: number) => (
                            <MenuItem value={row.id} key={index}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                      {CheckActiveTermininalInMin(row) > 60 && (
                                        <ClearOutlinedIcon
                                          style={{
                                            fontSize: "15",
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        ></ClearOutlinedIcon>
                                      )}

                                      {CheckActiveTermininalInMin(row) < 60 && (
                                        <CheckIcon
                                          style={{
                                            fontSize: "15",
                                            color:
                                              CheckActiveTermininalInMin(row) <
                                                10
                                                ? "green"
                                                : "orange",
                                            marginRight: "10px",
                                          }}
                                        ></CheckIcon>
                                      )}
                                    </td>
                                    <td>{row.terminalName}</td>
                                    <td>
                                      <Box
                                        style={{
                                          marginLeft: "8px",
                                          fontSize: "12px",
                                          color: "gray",
                                        }}
                                      >
                                        {CheckActiveTerminal(row)}
                                      </Box>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <Box style={{ marginTop: "20px", textAlign: "right" }}>
                        {/* <SaveCard /> */}

                        <Box>
                          <Button
                            fullWidth
                            style={{ minWidth: "200px", textTransform: "none" }}
                            disabled={
                              isProcessing ||
                              terminal == "0" ||
                              paymentProcessed
                            }
                            variant="contained"
                            onClick={(e) => ProcessPayment()}
                          >
                            {paymentProcessed ? (
                              <>
                                Payment{" "}
                                {paymentData.status.indexOf("Fail") >= 0
                                  ? paymentData.status
                                  : "Processed"}{" "}
                              </>
                            ) : (
                              <>
                                {!isProcessing && (
                                  <span>
                                    {JWTDecode(token, "TransactionTypeId") ===
                                      "1"
                                      ? "Return "
                                      : "Pay "}
                                    {dollarUSLocale.format(
                                      paymentData.totalAmount
                                    )}
                                  </span>
                                )}
                                {isProcessing && (
                                  <span>
                                    {paymentDone
                                      ? "Finalizing payment"
                                      : `Waiting for the payment (${dollarUSLocale.format(
                                        paymentData.totalAmount
                                      )})`}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <CircularProgress
                                      size="1rem"
                                      style={{
                                        color:
                                          paymentDone == true
                                            ? "green"
                                            : "orange",
                                      }}
                                    ></CircularProgress>
                                  </span>
                                )}
                              </>
                            )}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={classes.paymentBoxManual}
                  style={{ textAlign: "right" }}
                >
                  <SaveCard />
                </Box>
              </Box>
            )}
             {tabValue == "SaveCardTerminal" && (
              <Box>
                <Box
                  className={classes.paymentBox}
                  style={{ minWidth: !isMobile() ? "400px" : "100px" }}
                >
                  <Box style={{ border: "0px solid red" }}>
                    <Box>
                      <Header tab="SaveCardTerminal" ></Header>
                    </Box>

                    <Box style={{ marginTop: "15px" }}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="filter-type-label">
                          Payment Terminal
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="filter-type-label"
                          id="filter-type-select"
                          value={terminal}
                          onChange={(e) =>
                            setTerminal(e.target.value as string)
                          }
                          label="Payment Terminal 123"
                        >
                          <MenuItem value="0">Choose One</MenuItem>
                          {Terminals().map((row: any, index: number) => (
                            <MenuItem value={row.id} key={index}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "bottom" }}>
                                      {CheckActiveTermininalInMin(row) > 60 && (
                                        <ClearOutlinedIcon
                                          style={{
                                            fontSize: "15",
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        ></ClearOutlinedIcon>
                                      )}

                                      {CheckActiveTermininalInMin(row) < 60 && (
                                        <CheckIcon
                                          style={{
                                            fontSize: "15",
                                            color:
                                              CheckActiveTermininalInMin(row) <
                                                10
                                                ? "green"
                                                : "orange",
                                            marginRight: "10px",
                                          }}
                                        ></CheckIcon>
                                      )}
                                    </td>
                                    <td>{row.terminalName}</td>
                                    <td>
                                      <Box
                                        style={{
                                          marginLeft: "8px",
                                          fontSize: "12px",
                                          color: "gray",
                                        }}
                                      >
                                        {CheckActiveTerminal(row)}
                                      </Box>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <Box style={{ marginTop: "20px", textAlign: "right" }}>
                        {/* <SaveCard /> */}

                        <Box>
                          <Button
                            fullWidth
                            style={{ minWidth: "200px", textTransform: "none" }}
                            disabled={
                              isProcessing ||
                              terminal == "0" ||
                              paymentProcessed
                            }
                            variant="contained"
                            onClick={(e) => ProcessPayment(true)}
                          >
                            {paymentProcessed ? (
                              <>
                                Card{" "}
                                {paymentData.status.indexOf("Fail") >= 0
                                  ? paymentData.status
                                  : "Saved"}{" "}
                              </>
                            ) : (
                              <>
                                {!isProcessing && (
                                  <span>
                                    {JWTDecode(token, "TransactionTypeId") ===
                                      "1"
                                      ? "Return "
                                      : "Save Card "}
                                  </span>
                                )}
                                {isProcessing && (
                                  <span>
                                    {paymentDone
                                      ? "Saving card"
                                      : `Waiting for the Card Details`}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <CircularProgress
                                      size="1rem"
                                      style={{
                                        color:
                                          paymentDone == true
                                            ? "green"
                                            : "orange",
                                      }}
                                    ></CircularProgress>
                                  </span>
                                )}
                              </>
                            )}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={classes.paymentBoxManual}
                  style={{ textAlign: "right" }}
                >
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
