import { useAppDispatch } from "../app/hooks";
import { decodeToken } from "../helpers/helpers";
import { logout } from "../features/auth/authSlice";
import { setCredentials } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { setDealerId } from "../features/dealer/dealerSlice";

export const useCheckAuthToken = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let showContentOnly = params.get("showContentOnly") ?? params.get("showcontentonly"); //check to show the content only without header and side menu.
  localStorage.setItem('showContentOnly', showContentOnly === 'true' ? 'true' : 'false');

  let token = params.get("access_token");
  const ref_token = params.get("refresh_token");
  if (token) {
    dispatch(
      setCredentials({
        access_token: token,
        expires_in: 1800,
        refresh_token: ref_token,
        token_type: "Bearer",
        scope: "",
      })
    );
    localStorage.clear();
    localStorage.setItem("access_token", token);
    localStorage.setItem("refresh_token", ref_token);
    localStorage.setItem("onboardDealer_token", token);
    localStorage.setItem("isTLDomainUser", "false");
    localStorage.setItem('showContentOnly', showContentOnly === 'true' ? 'true' : 'false');

    return true;
  }
  if (!access_token || access_token === undefined) {
    return false;
  } else {
    const decodedJwt = decodeToken(access_token);
    let currentDate = new Date();
    // if token expired
    if (decodedJwt.exp * 1000 <= currentDate.getTime()) {
      // Redirect to Login page
      dispatch(logout());
      dispatch(setDealerId(""));
      return false;
    }
    return true;
  }
};
